import React from "react";

export const SidebarData = [
    {
        title: 'Home',
        path: '/',
        cName: 'nav-text',
        icon: 'icon_home.png'
    },
    {
        title: 'Categories',
        path: '/categories',
        cName: 'nav-text',
        icon: 'icon_category.png'
    },
    {
        title: 'Tags',
        path: '/tagcloud',
        cName: 'nav-text',
        icon: 'icon_tags.png'
    },
    {
        title: 'Formats',
        path: '/formats',
        cName: 'nav-text',
        icon: 'icon_format.png'
    },
    {
        title: 'About',
        path: '/about',
        cName: 'nav-text',
        icon: 'icon_about.png'
    }
];