import React from 'react';
import { Button } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { default as ImportedCategories } from '../data/categories.json';

class Categories extends React.Component
{
    createElements()
    {
        let elements: JSX.Element[] = [];
        ImportedCategories.forEach(value => 
        {
            let url = encodeURIComponent(value);
            let image = url.replaceAll("-", "_").replaceAll("%20%2F%20", "_").toLowerCase();
            elements.push(
                <div key={value} >
                    <Link className="btn-primary CategoryButton btn-lg" to={`${(this.props as any).match.url}/${url}`}>
                        <img src={"/icons/category/icon_" + image + ".png"} className="ContentRowImage" aria-hidden={true} />
                        {value}
                    </Link>
                </div>
            );
        });
        // CategoryData.forEach((value) => elements.push(
        //     // <div key={value.url} >
        //     //     <Link className="btn-primary CategoryButton btn-lg" to={`${(this.props as any).match.url}/${value.url}`}>{value.title}</Link>
        //     // </div>
        // ));
        return elements;
    }

    render()
    {
        return (
            <div className="d-grid gap-2">
                <div className="Header">Categories</div>
                {this.createElements()}
            </div>
        );
    }
}

export default Categories;
