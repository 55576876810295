import { useEffect } from 'react';

export default function Script(props:any)
{
    useEffect(() =>
    {
        const script = document.createElement("script");

        Object.assign(script, props);

        let { parent = "body" } = props;
        let parentNode = document.querySelector(parent);
        parentNode.appendChild(script);

        return () =>
        {
            parentNode.removeChild(script);
        }
    });

    return null;
}
