import React from "react";
import { Link } from "react-router-dom";
import ContentRow from "../components/contentrow";
import { default as ImportedContent } from "../data/entries.json"

class Category extends React.Component
{
    categoryData: { title: string, url: string } | undefined;
    category:string;

    constructor(props:{ match: { params: { category: string } } })
    {
        super(props);

        this.category = decodeURIComponent(props.match.params.category);
    }

    render()
    {
        return (
            <div className="d-grid gap-2">
                <div className="Header">{this.category}</div>
                {ImportedContent.filter(c => c.Category === this.category).map((item) => 
                    <ContentRow item={item} />
                )}
            </div>
        );
    }
}

export default Category;
