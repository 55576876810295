import React from 'react';
import * as FaIcons from 'react-icons/fa';
import * as AiIcons from 'react-icons/ai';
import { Link, NavLink, useLocation } from 'react-router-dom';
import { SidebarData } from './SideMenuData';

type NavbarState =
{
    sidebarOpen:boolean;
};

class Navbar extends React.Component<any, NavbarState>
{
    constructor(props:any)
    {
        super(props);

        this.showSidebar = this.showSidebar.bind(this);
        this.hideSidebar = this.hideSidebar.bind(this);
        this.state = { sidebarOpen: false };
    }

    showSidebar()
    {
        this.setState((state:{sidebarOpen:boolean}, props) => ({
            sidebarOpen: !state.sidebarOpen
        }));
    }

    hideSidebar()
    {
        if(this.state.sidebarOpen)
        {
            this.setState((state:{sidebarOpen:boolean}, props) =>({
                sidebarOpen: false
            }));
        }
    }

    render()
    {
        return (
            <>
                <div className="navbar" onClick={this.hideSidebar}>
                    <Link to="#" className="menu-bars">
                        <AiIcons.AiOutlineMenu onClick={this.showSidebar} />
                    </Link>
                    <img src="/Logo_make_music_work.png" alt="Make Music Work" className="AppLogo" />
                    <div className="menu-bars" style={{color: "#2193cf"}}>
                        <AiIcons.AiOutlineMenu />
                    </div>
                </div>
                <div className={this.state.sidebarOpen ? "nav-menu-underlay active" : "nav-menu-underlay"} onClick={this.showSidebar}>

                </div>
                <nav className={this.state.sidebarOpen ? 'nav-menu active' : 'nav-menu'}>
                    <ul className="nav-menu-items" onClick={this.showSidebar}>
                        <li className="navbar-toggle">
                            <Link to="#" className="menu-bars" style={{marginLeft: "5px"}}>
                                <AiIcons.AiOutlineClose />
                            </Link>
                        </li>
                        {SidebarData.map((item, index) =>
                        {
                            return (
                                <li key={index} className={item.cName}>
                                    <NavLink to={item.path}>
                                        <img src={"/icons/menu/" + item.icon} />
                                        <span>{item.title}</span>
                                    </NavLink>
                                </li>
                            );
                        })}
                    </ul>
                </nav>
            </>
        );
    }
}

function LocationNavbar()
{
    const location = useLocation();

    return location.pathname === "/" ? null : <Navbar />
}

export default LocationNavbar;
