import React from 'react';
import logo from './logo.svg';
import './App.css';
import Categories from './pages/Categories';
import { Redirect, Route, useLocation } from 'react-router';
// import Navbar from './Navbar';
import { BrowserRouter, HashRouter, Switch } from 'react-router-dom';
import Home from './pages/Home';
import Formats from './pages/Formats';
import Tags from './pages/Tags';
import Category from './pages/Category';
import Tag from './pages/Tag';
import Format from './pages/Format';
import withSplashScreen from './withSplashScreen';
import LocationNavbar from './Navbar';
import AudioContent from './pages/AudioContent';
import MyCloud from './pages/TagCloud';
import About from './pages/About';

function App() {
  return (
    <div className="App">
      <HashRouter>
        <LocationNavbar />
        <Switch>
          <Route path='/' exact component={Home} />
          <Route path='/categories' exact component={Categories} />
          <Route path='/categories/:category' component={Category} />
          <Route path='/tagcloud' exact component={MyCloud} />
          <Route path='/tags' exact component={Tags} />
          <Route path='/tags/:tag' component={Tag} />
          <Route path='/formats' exact component={Formats} />
          <Route path='/formats/:format' component={Format} />
          <Route path='/audiocontent/:title' component={AudioContent} />
          <Route path='/about' exact component={About} />
          <Redirect to="/" />
        </Switch>
      </HashRouter>
    </div>
  );
}

export default withSplashScreen(App);
