import React from "react";
import ContentRow from "../components/contentrow";
import { default as ImportedContent } from "../data/entries.json"

class Format extends React.Component
{
    formatData: { title: string, url: string } | undefined;
    format:string;

    constructor(props: { match: { params: { format: string } } })
    {
        super(props);

        this.format = props.match.params.format;
        //this.formatType = props.match.params.format;
        //this.formatData = FormatData.get(this.formatType);
    }

    render()
    {
        return (
            <div className="d-grid gap-2">
                <div className="Header">{this.format}</div>
                {
                    ImportedContent.filter(c => c.Format === this.format).map(item =>
                        <ContentRow item={item} />
                    )
                }
            </div>
        );
    }
}

export default Format;
