import React, { useCallback } from 'react';
import WordCloud from 'react-d3-cloud';
import { default as ImportedContent } from "../data/entries.json";
import { default as ImportedTags } from "../data/tags.json";
import { Link, RouteComponentProps, useHistory, withRouter } from "react-router-dom";
import PropTypes from "prop-types";

const data = [
    { text: 'Hey', value: 1000 },
    { text: 'lol', value: 200 },
    { text: 'first impression', value: 800 },
    { text: 'very cool', value: 1000000 },
    { text: 'duck', value: 10 },
  ];

const colours = [
    "#FF931E",
    "#ECC977",
    "#F0FD96",
    "#FCF4BA",
    "#DDFAC7"
]

interface CloudState
{
    dimensions: { width: number, height: number } | null;
}

class TagCloud extends React.Component<RouteComponentProps, CloudState>
{
    data;
    cloudContainer:HTMLElement | null = null;
    scale = 1;

    constructor(props:RouteComponentProps)
    {
        super(props);

        this.onWordClick = this.onWordClick.bind(this);
        this.fontSize = this.fontSize.bind(this);
        this.padding = this.padding.bind(this);

        this.data = ImportedTags.map(item =>
        {
            return { text: item, value: ImportedContent.flatMap(entry => entry.Tags).filter(entry => entry === item).length }
        });

        this.state = { dimensions: null }
    }

    componentDidMount()
    {
        if(this.cloudContainer != null)
        {
            this.setState(
                {
                    dimensions: 
                    {
                        width: this.cloudContainer.offsetWidth,
                        height: window.outerHeight - this.cloudContainer.offsetTop
                    }
                }
            )

            this.scale = this.cloudContainer.offsetWidth / 320;
        }
    }

    fontSize(word:any): number
    {
        return (Math.log2(word.value * 10) * 3) * this.scale;
    }

    padding(word:any): number
    {
        return 2 * this.scale;
    }

    onWordClick(event:any, d:any)
    {
        this.props.history.push("/tags/" + encodeURIComponent(d.text));
    }

    rotation(word:any, index:number)
    {
        return (index % 4) * 90;
    }
    
    colour(word:any)
    {
        let colour = colours[Math.floor(Math.random() * colours.length)];
        return colour;
    }

    renderCloud()
    {
        const { dimensions } = this.state;

        if(dimensions)
        {
            return (
                <WordCloud
                    data={this.data}
                    width={dimensions.width}
                    height={dimensions.height}
                    font="Avenir-Medium"
                    //fontStyle="italic"
                    fontWeight="bold"
                    fontSize={this.fontSize}
                    spiral="archimedean"
                    rotate={0}
                    //rotate={(word) => word.value % 360}
                    padding={this.padding}
                    random={Math.random}
                    fill={this.colour}
                    // fill={(d, i) => schemeCategory10ScaleOrdinal(i)}
                    onWordClick={this.onWordClick}
                    // onWordMouseOver={(event, d) => {
                    //     console.log(`onWordMouseOver: ${d.text}`);
                    // }}
                    // onWordMouseOut={(event, d) => {
                    //     console.log(`onWordMouseOut: ${d.text}`);
                    // }}
                />
            );
        }
        else return null;
    }

    render() 
    {
        const { dimensions } = this.state;
        return (
            <>
                <Link to="/tags" className="tagslist-btn"><img src="/icons/menu/icon_list_alpha.png" style={{height: "25px", marginRight: "5px"}} />List</Link>
                <div ref={el => this.cloudContainer = el}>
                    {dimensions && this.renderCloud()}
                </div>
                {/* <WordCloud
                    data={this.data}
                    width={640}
                    height={1136}
                    font="Avenir-Medium"
                    //fontStyle="italic"
                    fontWeight="bold"
                    fontSize={this.fontSize}
                    spiral="archimedean"
                    rotate={0}
                    //rotate={(word) => word.value % 360}
                    padding={7}
                    random={Math.random}
                    fill={this.colour}
                    // fill={(d, i) => schemeCategory10ScaleOrdinal(i)}
                    onWordClick={this.onWordClick}
                    // onWordMouseOver={(event, d) => {
                    //     console.log(`onWordMouseOver: ${d.text}`);
                    // }}
                    // onWordMouseOut={(event, d) => {
                    //     console.log(`onWordMouseOut: ${d.text}`);
                    // }}
                /> */}
            </>
        );
    }
}

export default withRouter(TagCloud);
