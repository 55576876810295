import React from "react";
import ContentRow from "../components/contentrow";
import { default as ImportedContent } from "../data/entries.json";

class Tag extends React.Component
{
    tagData: { title:string, url: string } | undefined;
    tag: string;

    constructor(props:{ match: { params: { tag:string } } })
    {
        super(props);

        this.tag = decodeURIComponent(props.match.params.tag);
        //this.tagType = props.match.params.tag;
        //this.tagData = TagData.get(this.tagType);
    }

    render()
    {
        return (
            <div className="d-grid gap-2">
                <div className="Header">{this.tag}</div>
                {
                    ImportedContent.filter(c => c.Tags.includes(this.tag)).map(item =>
                        // <div key={item.NR} >
                        //     <a className="btn-primary CategoryButton btn-lg" href={item.Url} target="_blank">{item.Content}</a>
                        // </div>
                        <ContentRow item={item} />
                    )
                }
            </div>
        );
    }
}

export default Tag;
