import React from "react";
import { Link } from "react-router-dom";

class Home extends React.Component
{
    render()
    {
        return (
            <div>
                <img src="/Logo_make_music_work.png" className="AppLogoHome" />
                {/* <div className="Header">Home</div> */}
                
                <p className="subtitle"><i>tools, tips and tags<br />for an entrepreneurial mindset</i></p>

                <div className="d-grid gap-2">
                    <div key="categories">
                        <Link className="btn-primary CategoryButton btn-lg" to="/categories">
                            <img src="/icons/menu/icon_category.png" className="ContentRowImage" aria-hidden={true} />
                            Categories
                        </Link>
                    </div>
                    <div key="formats">
                        <Link className="btn-primary CategoryButton btn-lg" to="/formats">
                            <img src="/icons/menu/icon_format.png" className="ContentRowImage" aria-hidden={true} />
                            Formats
                        </Link>
                    </div>
                    <div key="tags">
                        <Link className="btn-primary CategoryButton btn-lg" to="/tagcloud">
                            <img src="/icons/menu/icon_tags.png" className="ContentRowImage" aria-hidden={true} />
                            Tags
                        </Link>
                    </div>
                    <div key="about">
                        <Link className="btn-primary CategoryButton btn-lg" to="/about">
                            <img src="/icons/menu/icon_about.png" className="ContentRowImage" aria-hidden={true} />
                            About
                        </Link>
                    </div>
                </div>
            </div>
        );
    }
}

export default Home;
