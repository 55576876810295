import React from "react";
import { default as ImportedContent } from "../data/entries.json";

class AudioContent extends React.Component
{
    title: string;

    constructor(props:{ match: { params: { title:string } } })
    {
        super(props);

        this.title = decodeURIComponent(props.match.params.title);
    }

    render()
    {
        let content = ImportedContent.find(item => item.Content === this.title);
        return (
            <div>
                <div className="AudioHeader">{this.title}</div>
                <audio controls style={{paddingLeft: "20px", paddingRight: "20px"}}>
                    <source src={"/resources/" + content?.Filename} />
                </audio>
            </div>
        );
    }
}

export default AudioContent;
