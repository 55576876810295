import React from "react";
import { Link } from "react-router-dom";
import { default as ImportedContent } from "../data/entries.json"

type ContentType = { item: { NR: string, Url: string, Content: string, Medium: string, Format: string, Filename: string } };

class ContentRow extends React.Component<ContentType, {}>
{
    render()
    {
        let image = this.props.item.Format.toLowerCase().replaceAll(" ", "_");

        if(this.props.item.Medium === "website")
        {
            return (
                <div key={this.props.item.NR} >
                    <a className="btn-primary CategoryButton btn-lg" style={{display: "flex", alignItems: "center"}} href={this.props.item.Url} target="_blank">
                        <img src={"/icons/format/icon_" + image + ".png"} className="ContentRowImage" aria-hidden={true} />
                        <div>{this.props.item.Content}</div>
                    </a>
                </div>
            );
        }
        else if(this.props.item.Medium === "pdf")
        {
            if(!this.props.item.Filename) return null;

            let url = "/resources/" + this.props.item.Filename;
            return (
                <div key={this.props.item.NR} >
                    <a className="btn-primary CategoryButton btn-lg" style={{display: "flex", alignItems: "center"}} href={url} target="_blank">
                        <img src={"/icons/format/icon_" + image + ".png"} className="ContentRowImage" aria-hidden={true} />
                        <div>{this.props.item.Content}</div>
                    </a>
                </div>
            );
        }
        else if(this.props.item.Medium === "audio")
        {
            let url = "/audiocontent/" + encodeURIComponent(this.props.item.Content);
            return (
                <div key={this.props.item.NR} >
                    <Link className="btn-primary CategoryButton btn-lg" style={{display: "flex", alignItems: "center"}} to={url}>
                        <img src={"/icons/format/icon_" + image + ".png"} className="ContentRowImage" aria-hidden={true} />
                        <div >{this.props.item.Content}</div>
                    </Link>
                </div>
            );
        }
        else
        {
            return (
                <div key={this.props.item.NR} >
                    <Link className="btn-primary CategoryButton btn-lg" style={{display: "flex", alignItems: "center"}} to="#" target="_blank">
                        <img src={"/icons/format/icon_" + image + ".png"} className="ContentRowImage" aria-hidden={true} />
                        <div>{this.props.item.Content}</div>
                    </Link>
                </div>
            );
        }
    }
}

export default ContentRow;
