import React from "react";

class About extends React.Component
{
    render()
    {
        return (
            <div className="AboutText">
                <p>In 2017 the<span style={{color: "blue", textDecorationLine: "underline"}}>Association Européenne des Conservatoires, Académies de Musique et Musikhochschulen (AEC)</span> won 4-year funding from Creative Europe  for a project
called Strengthening Music in Society (SMS).  This project marked an
ambitious next step in the organisation’s work.</p>

<p>‘Strengthening Music in Society’
takes a particular lens on the broad agenda that the title suggests, focusing
on the education of the next generations of professional musicians through
specialist training institutions: what is needed in developing this education
to ensure that emerging professional musicians are able to make flourishing
lifelong careers and contribute to the societies in which they live; and
equally in what ways these institutions may strengthen the part they play
within their own localities, and the societal impact they have through the work
they do with young musicians.</p>

<p>Through this project, together
with experts from AEC member institutions and partner organisations who have
formed different Working Groups (WGs), AEC has been pursuing the following
objectives:</p>

<p>1. To raise consciousness of contemporary potential and
challenges for musicians and HMEIs in society, and how these interact with the
complex responsibilities that HMEIs have, artistically, educationally,
economically, socially and environmentally.</p>

<p>2. To encourage HMEIs to open their educational offer towards
more diversity and to promote inclusiveness throughout their activities.</p>

<p>3. To embed entrepreneurial skills in the education of the
artist to better prepare students for their future role as musician-entrepreneur.</p>

<p>4. To help music students and teachers internationalise their
careers and activities, and to achieve a greater impact of the most important
online tool for student mobility in HME.</p>

<p>5. To explore and discuss new Learning &amp; Teaching models
enabling HMEIs to educate creative and communicating musicians.</p>

<p>6. To encourage the use of digital technologies in music
education.</p>

<p>7. To strengthen student voice within the Association and
within all AEC member institutions and to establish a European network of HME
students.</p>

<p>8. To increase the quality of early childhood music education
and thus extend the audience of tomorrow.</p>

<p>AEC organizes its different platforms and activities through the
establishment of different WGs, composed by experts in different areas within
the field of Higher Music Education. Proactivity, as well as geographical and
gender balances are the applied criteria to guarantee the diverse and dynamic
character of the groups, who recreate the diversity of AEC membership. In the
frame of the AEC - Strengthening Music in Society (SMS) project, each strand is
carried out by a WG composed by representatives of AEC member institutions, a
student representative, a WG coordinator and relevant external partners</p>
            </div>
        );
    }
}

export default About;
