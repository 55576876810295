import React, { Component } from "react";
import Script from "./Script";

function LoadingMessage()
{
    return (
        <div style={{width: "100vw", height: "100vh", backgroundColor: "#2093CF"}}>
            <div id="logommwv9_hype_container" className="HYPE_document" style={{margin: "auto", position: "relative", top: (((window.outerHeight - 568) / 2) - 15) + "px", width: "320px", height: "568px", overflow: "hidden", backgroundColor: "#2093CF"}}>
                <Script async={false} type="text/javascript" charSet="utf-8" src="/logo%20MMW%20v9.hyperesources/logommwv9_hype_generated_script.js?70486"></Script>
            </div>
        </div>
    );
}

interface IWithLoadingProps
{
    //loading: boolean;
}

const withSplashScreen = <P extends object>(Component: React.ComponentType<P>) =>
{
    return class WithSplashScreen extends React.Component<P & IWithLoadingProps>
    {
        constructor(props:P & IWithLoadingProps)
        {
            super(props);

            this.state = { loading: true };
        }

        async componentDidMount()
        {
            setTimeout(() =>
            {
                this.setState({ loading: false });
            }, 5500);
        }

        render()
        {
            const { ...props } = this.props;
            return (this.state as any).loading ? <LoadingMessage /> : <Component {...props as P} />
        }
    };
}

export default withSplashScreen;
