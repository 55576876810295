import React from "react";
import { Link } from "react-router-dom";
import { default as ImportedFormats } from '../data/formats.json';

class Formats extends React.Component
{
    createElements()
    {
        let elements: JSX.Element[] = [];
        ImportedFormats.forEach(value => 
        {
            let url = encodeURIComponent(value);
            let image = url.replaceAll("%20", "_").toLowerCase();
            elements.push(
                <div key={value} >
                    <Link className="btn-primary CategoryButton btn-lg" to={`${(this.props as any).match.url}/${value}`}>
                        <img src={"/icons/format/icon_" + image + ".png"} className="ContentRowImage" aria-hidden={true} />
                        {value}
                    </Link>
                </div>
            );
        });
        // FormatData.forEach(value => elements.push(
        //     <div key={value.url} >
        //         <Link className="btn-primary CategoryButton btn-lg" to={`${(this.props as any).match.url}/${value.url}`}>{value.title}</Link>
        //     </div>
        // ));
        return elements;
    }
    
    render()
    {
        return (
            <div className="d-grid gap-2">
                <div className="Header">Formats</div>
                {this.createElements()}
            </div>
        );
    }
}

export default Formats;
